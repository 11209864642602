<template>
    <div class="downloadapp">
        <Tabs></Tabs>
        <div class="banner">
            <div class="w1400">
                <div class="l">
                    <h2 style="font-style: italic;">
                        <i>手机学习</i>
                    </h2>
                    <p>
                        扫码极速打开，随时随地学习、刷题，
                        考试快人一步！！！
                    </p>
                </div>
                <div class="r">
                    <img src="../../assets/img/page_background.png" alt />
                </div>
            </div>
        </div>
        <div class="list">
            <div class="box" @click="open(`/help/downlod.html`)">
                <div class="l">
                    <img src="https://res-qd.qinxueedu.cn/uploads/prod/20230412/86956d8b637e37f3c89303956844ef2b.png"
                        alt />
                </div>
                <div class="r">
                    <div class="t">
                        勤学课堂APP
                        <a class="but">
                            了解更多
                        </a>
                    </div>
                    <p>手机电脑同步听课，海量免费公开课供您选择</p>
                    <p>会员课程离线下载，让您随时随地听课学习</p>
                    <p>随机/章节精练、历年直题、模拟考试、海量题库实时检测学习成果</p>
                </div>
            </div>
            <div class="box">
                <div class="l">
                    <img src="https://res-qd.qinxueedu.cn/uploads/prod/20230724/7218a43f5467a06851484093dd706deb.png" alt />
                </div>
                <div class="r">
                    <div class="t">
                        <!-- 勤学随身课堂 -->
                        <img src="../../assets/img/xiaochengxu2.png" alt="勤学随身课堂"/>
                    </div>
                    <p>微信小程序，极速打开，无需下载即可学习</p>
                    <p>海量免费试听课、会员课程，放在口袋里的随身课堂</p>
                    <p>前沿资讯一网打尽，金牌班主任倾力辅导</p>
                </div>
            </div>
            
        </div>
        <Foot></Foot>
    </div>
</template>

<script>
export default {
    name: 'Downloadapp',
    data() {
        return {

        }
    },
    methods: {
        open(e) {
            console.log(window.location)
            console.log(e)
            window.open(this.url.baseUrl + e)
        }
    },
    created() {

    }
}
</script>

<style lang="scss" scoped>
.list {
    width: 1400px;
    margin: 0 auto;
    line-height: 1;
    padding: 60px 0 140px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .box:hover {
        transform: scale(1.03);
    }

    .box {
        padding: 30px;
        background: #ffffff;
        box-shadow: 0px 4px 8px 0px rgba(0, 27, 89, 0.05);
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;
        cursor: pointer;
        transition: all 0.3s;

        .l {
            width: 168px;
            height: 168px;
            margin-right: 35px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .r {
            .t {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 23px;
                font-size: 24px;
                font-family: Microsoft YaqiHei;
                font-weight: bold;
                color: #1c1c1c;

                .but {
                    padding: 8px 18px;
                    font-size: 16px;
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #ffffff;
                    background: #3e7eff;
                    border-radius: 6px;
                }

                img {
                    height: 55px;
                }
            }

            p {
                width: 399px;
                padding-left: 15px;
                font-size: 16px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #333333;
                line-height: 23px;
                margin-bottom: 13px;
                position: relative;
            }

            p::after {
                content: "";
                display: block;
                width: 10px;
                height: 10px;
                background: url(../../assets/img/langou.png) no-repeat center;
                background-size: 100%;
                position: absolute;
                top: 5px;
                left: 0;
            }
        }
    }
}

.banner {
    background: #4379f6;

    .w1400 {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .l {
        text-align: left;

        h2 {
            font-size: 50px;
            font-family: Microsoft YaqiHei;
            color: #ffffff;
            font-weight: bold;
            text-shadow: 0px 4px 8px rgba(0, 31, 102, 0.1);
            margin-bottom: 19px;
        }

        p {
            width: 332px;
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 27px;
        }
    }
}

.w1400 {
    width: 1400px;
    margin: 0 auto;
}
</style>